
.image-container-blur {
 
  /* Add background styles */
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../src/assets//bgmain.png');
}
.MuiFormControlLabel-label{
  font-size: 13px !important;
}

.image-container {
 
  /* Add background styles */
  background-position: center;
  background-size: cover;
  background-image: url('../src/assets//bgmain.png');
}



.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.card {
  width: 300px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

/* styles.css */
.slide {
  width: 300px;
  height: 200px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.swiper {
  padding-bottom: 50px !important;
}
/* Swiper Navigation Styles */
.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  font-size: 18px;
  background-color: #eb0a1e; /* Set the background color of your navigation arrows */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;

  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next {
  left: 94% !important;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  content: "›";
}

.swiper-button-prev::after {
  content: "‹";
}

.bg4chatanswer {
  background: #ffffff;
  background: linear-gradient(136deg, #ffffff 0%, #bababa 100%);
}

.markdown-table {
  width: 100%;
  border-collapse: unset;
  border-radius: 1px; /* Set border-radius as needed */
  overflow: auto; /* Clip content to rounded corners */

  font-family: Arial, sans-serif;
  color: black;
}

.markdown-table th,
.markdown-table td {
  padding: 4px;
  text-align: center !important;
}
/* 
.markdown-table th {
  background-color: #fdfbfb; 
} */

.markdown-table tbody tr {
  border-bottom: 1px solid;
}

.markdown-table thead tr {
  border-bottom: 3px solid;
}

.markdown-table thead tr th:first-child {
  text-align: left !important;
}

.markdown-table tr:hover {
  background-color: #f2eafb; /* Hovered row background color */
}

.markdown-table td:first-child {
  font-weight: bold; /* Bold text in the first column */
  text-align: left !important;
}

.markdown-table tbody tr:last-child {
  border-bottom: none !important;
}

.typed {
  font-size: 100%;
  text-align: left;

  vertical-align: text-bottom;
}

.typed::after {
  content: "❚";
  font-family: Arial, sans-serif;
  font-size: 100%;
  line-height: 20px;
  font-weight: 900;
  animation: blink 0.75s step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: rgba(41, 38, 38, 0.579);
  }
}

.style-3:hover {
  -webkit-mask-position: left top;
}
.style-3::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 2 10 56px rgba(0, 0, 0, 0.488);
	background-color: #f3f3f30a; */
}

.style-3::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* background-color: #f3f3f30a; */
}

.style-3::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.605);
}

.link {
  color: black;
}

.rotate {
  animation: mymove 2s infinite;
}

.animated-div {
  opacity: 0;
  display: none;
  transition: opacity 5s ease, display 5s ease;
}

.myvisible {
  opacity: 1;
  display: block;
}

.myhidden {
  opacity: 0;
  display: none;
}
[data-rmiz-modal-overlay="visible"] {
  background: linear-gradient(136deg, #ffffff 0%, #bababa 100%) !important;
}
@keyframes mymove {
  50% {
    transform: rotate(50deg);
  }
}

@media screen and (max-width: 580px) {
  .style-3::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .style-3 {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
