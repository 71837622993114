@font-face {
  font-family: 'ToyotaFont';
  src: url('/src/assets/fonts/ToyotaType-Regular.otf') format('woff'); /* Adjust the path accordingly */
  font-weight: normal;
  font-style: normal;
}


*{

  box-sizing: border-box;
}



body {
  font-family: 'ToyotaFont', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;